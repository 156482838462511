<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
        <!-- Left Text-->
        <b-col
            lg="8"
            class="d-none d-lg-flex align-items-center p-5"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
                fluid
                :src="imgUrl"
                alt="Login V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
            lg="4"
            class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
              sm="8"
              md="6"
              lg="12"
              class="px-xl-2 mx-auto"
          >
            <b-card-title
                class="mb-1 font-weight-bold"
                title-tag="h2"
            >
              Welcome to {{ appName }}! 👋
            </b-card-title>
            <b-card-text class="mb-2">
              Please sign-in to your account and start the adventure
            </b-card-text>


            <!-- form -->
            <validation-observer
                ref="loginForm"
                #default="{invalid}"
            >
              <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                    label="Email"
                    label-for="login-email"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Email"
                      vid="email"
                      rules="required|email"
                  >
                    <b-form-input
                        id="login-email"
                        :state="errors.length > 0 ? false:null"
                        v-model="mail"
                        name="login-email"
                        placeholder="Your email..."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{name:'auth-forgot-password'}">
                      <!--                    <small>Forgot Password?</small>-->
                    </b-link>
                  </div>
                  <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="password"
                      rules="required"
                  >
                    <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="*****"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                      id="remember-me"
                      v-model="status"
                      name="checkbox-1"
                  >
                    Remember Me
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                    type="submit"
                    variant="primary"
                    block
                    :disabled="invalid"
                >
                  Sign in
                </b-button>

              </b-form>
            </validation-observer>
          </b-col>
        </b-col>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import appName from "@/main";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BOverlay
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {required, email, integer} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store/index'
import {getHomeRouteForLoggedInUser} from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  title:"Login",
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    BOverlay,

    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      appName,
      status: '',
      password: '',
      mail: '',
      showOverlay: false,
      // userEmail: 'admin@demo.com',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      integer,
      email
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.showOverlay = true;
          useJwt.login({
            mobile: '',
            mail: this.mail,
            firstName: "",
            lastName: "",
            password: this.password,
            verifyCode: "",
            encryptedMail: "",
            // userName: "string",
            userRole: 0,
            type: 2,
            nationalId: "",
            instagramId: "",
            sexuality: 1,
            introducerCode: ""
          })
              .then(response => {
                this.showOverlay = false;

                // Errors
                if (response.data.status === 3) {
                  return this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-left',
                    props: {
                      variant: 'danger',
                      text: "User name or password is wrong!",
                    },
                  })
                } else if (response.data.status === 4) {
                  return this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-left',
                    props: {
                      variant: 'danger',
                      text: "Your verification code has been expired , try again",
                    },
                  })
                } else if (response.data.status === 5) {
                  return this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-left',
                    props: {
                      variant: 'danger',
                      text: "Verification code is wrong!",
                    },
                  })
                } else if (response.data.status === 6) {
                  return this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-left',
                    props: {
                      variant: 'danger',
                      text: "User not found!",
                    },
                  })
                } else if (response.data.status === 8) {
                  return this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-left',
                    props: {
                      variant: 'danger',
                      text: "User exist!",
                    },
                  })
                } else if (response.data.status === 9) {
                  return this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-left',
                    props: {
                      variant: 'danger',
                      text: "Your password is weak!",
                    },
                  })
                } else if (response.data.status === 10) {
                  return this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-left',
                    props: {
                      variant: 'danger',
                      text: "User name exist!",
                    },
                  })
                } else if (response.data.status === 11) {
                  return this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-left',
                    props: {
                      variant: 'danger',
                      text: "You have been deactivated!",
                    },
                  })
                }

                // Success
                const userData = response.data
                userData["ability"] = [
                  {
                    action: "manage",
                    subject: "all",
                  },
                ];
                const userRole = userData.data.user.role;

                if (userRole === 'User') {
                  return this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-left',
                    props: {
                      variant: 'danger',
                      text: "You dont have the permission to log in !",
                    },
                  })
                }

                if (userRole === 'Blogger') {
                  userData.ability[0].action = 'Blogger';
                  userData.ability[0].subject = 'Blogger';
                }
                if (userRole === 'Accountent') {
                  userData.ability[0].action = 'Accountent';
                  userData.ability[0].subject = 'Accountent';
                }
                if (userRole === 'ProductManager') {
                  userData.ability[0].action = 'ProductManager';
                  userData.ability[0].subject = 'ProductManager';
                }
                if (userRole === 'Support') {
                  userData.ability[0].action = 'Support';
                  userData.ability[0].subject = 'Support';
                }

                useJwt.setToken(response.data.data.token)
                useJwt.setRefreshToken(response.data.data.token)
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$ability.update(userData.ability)

                // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router.replace(getHomeRouteForLoggedInUser(userRole))
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-left',
                        props: {
                          title: `${userData.data.user.name}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully logged in as ${userData.data.user.role}. Now you can start to explore!`,
                        },
                      })
                    })
              })
              .catch(error => {
                console.log(error)
              })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
